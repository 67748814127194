import { StaticQuery, graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from '@reach/router';

import { usePrivacyConsent } from '../hooks/usePrivacy';
import { ASIA_CODES, ESPI_TWITTER, EU_CODES, REGIONS } from '../helpers';
import { resolve } from '../urls';

const query = graphql`
  query {
    allContentfulPartGlobals {
      edges {
        node {
          siteName
          siteDescription
          siteShareImage {
            title
            file {
              url
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
        name
      }
    }
  }
`;

const ALTERNATE_LOCALES = [REGIONS.us, REGIONS.eu, REGIONS.asia];

const SEO = (props) => {
  const { allContentfulPartGlobals, site } = useStaticQuery(query);
  const { title, description, shareImage, structuredData, metadata, routeName, routeParams } =
    props;
  const { pathname } = useLocation();

  // Grab fallback global meta info
  const globals = allContentfulPartGlobals.edges[0];
  const globalTitle = globals.node.siteName;
  const globalDescription = globals.node.siteDescription;
  const globalShareImg = globals.node.siteShareImage.file.url;

  // Set site meta
  const siteTitle = title || globalTitle;
  const siteDescription = description || globalDescription;
  const siteShareImg = shareImage || globalShareImg;

  const canUseAnalytics = usePrivacyConsent('analytics');

  // 1. check if overwritten metadata reference object from contentful is available
  // 2. if not use default props from the component
  // 3. if those are not available use the global metadata info
  return (
    <Helmet
      title={metadata?.page_title || siteTitle}
      titleTemplate={site.siteMetadata.titleTemplate}
    >
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta
        name="description"
        content={metadata?.page_description?.internal?.content || siteDescription}
      />
      <meta name="robots" content={metadata?.page_robots || 'index, follow'} />
      <meta
        name="image"
        content={`${site.siteMetadata.siteUrl}${
          metadata?.sharing_image?.file?.url || siteShareImg
        }`}
      />
      {metadata?.page_keywords && (
        <meta name="keywords" content={metadata.page_keywords.join(', ')} />
      )}
      {metadata?.page_canonical && <link rel="canonical" href={metadata.page_canonical} />}

      {/* Open Graph */}
      <meta property="og:title" content={metadata?.sharing_title || siteTitle} />
      <meta
        property="og:description"
        content={metadata?.sharing_description?.internal?.content || siteDescription}
      />
      <meta property="og:url" content={`${site.siteMetadata.siteUrl}${pathname}`} />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={`${site.siteMetadata.siteUrl}${
          metadata?.sharing_image?.file?.url || siteShareImg
        }`}
      />
      {metadata?.sharing_image && <meta property="og:image:width" content="1200" />}
      {metadata?.sharing_image && <meta property="og:image:height" content="630" />}
      <meta property="og:locale" content="en" />
      <meta property="og:site_name" content={site.siteMetadata.name} />

      {/* Twitter */}
      <meta name="twitter:title" content={metadata?.sharing_title || siteTitle} />
      <meta
        name="twitter:description"
        content={metadata?.sharing_description?.internal?.content || siteDescription}
      />
      <meta name="twitter:url" content={`${site.siteMetadata.siteUrl}${pathname}`} />
      <meta name="twitter:creator" content={ESPI_TWITTER} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content={`${site.siteMetadata.siteUrl}${
          metadata?.sharing_image?.file?.url || siteShareImg
        }`}
      />

      {/* Icon */}
      <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />

      <link rel="icon" type="image/png" sizes="16x16" href="/img/meta/favicon-16x16.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/img/meta/favicon-32x32.png" />

      <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-57x57.png" sizes="57x57" />
      <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-60x60.png" sizes="60x60" />
      <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-72x72.png" sizes="72x72" />
      <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-76x76.png" sizes="76x76" />
      <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-114x114.png" sizes="114x114" />
      <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-120x120.png" sizes="120x120" />
      <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-144x144.png" sizes="144x144" />
      <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-152x152.png" sizes="152x152" />
      <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon-180x180.png" sizes="180x180" />

      <meta name="application-name" content={site.siteMetadata.name} />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="/img/meta/mstile.png" />
      <meta name="msapplication-square70x70logo" content="/img/meta/mstile-70x70.png" />
      <meta name="msapplication-square150x150logo" content="/img/meta/mstile-150x150.png" />
      <meta name="msapplication-wide310x150logo" content="/img/meta/mstile-310x150.png" />
      <meta name="msapplication-square310x310logo" content="/img/meta/mstile-310x310.png" />

      {routeName &&
        ALTERNATE_LOCALES.map((lang) => {
          const pathnameLocale = resolve(routeName, { ...routeParams, locale: lang });
          const href = `${process.env.GATSBY_SITE_ORIGIN}${pathnameLocale}`;
          switch (lang) {
            case REGIONS.us:
              return <link key={lang} rel="alternate" hrefLang={lang} href={href} />;
            case REGIONS.eu:
              return EU_CODES.map((code, i) => (
                <link rel="alternate" href={href} hrefLang={`en-${code}`} key={`eu-code${i}`} />
              ));
            case REGIONS.asia:
              return ASIA_CODES.map((code, i) => (
                <link rel="alternate" href={href} hrefLang={`en-${code}`} key={`asia-code${i}`} />
              ));
            default:
              return null;
          }
        })}

      {/* Google Structured Data */}
      {structuredData &&
        structuredData.map((data, i) => {
          return (
            <script key={`script-${i}`} type="application/ld+json">{`${JSON.stringify(
              data
            )}`}</script>
          );
        })}

      {canUseAnalytics && (
        <script
          type="text/javascript"
          defer="defer"
          src="//script.leadboxer.com/?dataset=f1937ff235d186c0773290eb773d3809"
        />
      )}
    </Helmet>
  );
};

export default SEO;
